import { FC, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
  ThreeModel,
  ThreeTransdimensionalRect,
  TransdimensionalRect,
} from '../..';
import { enhance } from '../../../utilities';
import { useMaterial } from '../../../hooks';
import { ResumeContainer } from '.';

export const ResumeBiographyThree: FC = () => {
  const theme = useTheme();
  const basicMaterial2 = useMaterial(
    enhance(theme.palette.background.object, 0.45),
    {
      convertSRGBToLinear: true,
    }
  );

  return (
    <ThreeTransdimensionalRect id="resumeDocument_biographyQuotationMarks">
      <ThreeModel
        id="quotation_marks"
        position={[0, 0, -0.0625 / 2]}
        scale={[1, 1, 0.0625]}
        rotation={[0, 0, 0]}
        material={basicMaterial2}
      />
    </ThreeTransdimensionalRect>
  );
};

export const ResumeBiography: FC = () => {
  const theme = useTheme();
  const dividerColor = theme.palette.background.default;

  return (
    <ResumeContainer>
      <Box
        component="div"
        sx={{
          position: 'relative',
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: '94px',
          paddingBottom: '16px',
          minHeight: 48,
          '&::before': {
            content: "''",
            position: 'absolute',
            left: 70,
            top: 0,
            width: 'calc(100% - 70px)',
            height: '100%',
            borderLeft: '2px solid',
            borderBottom: '2px solid',
            borderColor: dividerColor,
            pointerEvents: 'none',
          },
          '&::after': {
            content: "''",
            position: 'absolute',
            left: theme.spacing(-4),
            bottom: 0,
            width: `calc(70px + ${theme.spacing(4)})`,
            height: 2,
            background: `linear-gradient(to left, ${dividerColor} 0%, ${theme.palette.background.clear} 100%)`,
          },
        }}
      >
        <TransdimensionalRect
          id="resumeDocument_biographyQuotationMarks"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: 48,
            height: 48,
          }}
        />
        <Typography variant="body1">
          {/* I'm an experienced software engineer specializing in
              human-computer interaction. My favorite thing to do is create
              rich, beautiful, interactive user experiences. I leverage a deep
              understanding of web technologies, design acumen, and
              interpersonal skills to bring products to life. */}
          I'm a recently-graduated paramedic with EMS experience that includes
          911 response and interfacility transportation. My goal is to obtain
          employment with a paramedic service in Dane County as I establish my
          EMS career and improve my skills as an ALS provider.
        </Typography>
      </Box>
    </ResumeContainer>
  );
};
