import { FC, PropsWithChildren, ReactNode } from 'react';

import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { TransdimensionalUnitConversionServiceContext } from '../../../contexts';
import {
  Badge,
  BrandCircle,
  Chip,
  ChipList,
  ChipThree,
  DocumentPage,
  Icon,
  LayoutAnchor,
  Logo,
  LogoFillVariant,
  NoWrap,
  ThemeInverter,
  ThemeProvider,
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeModel,
  ThreeQuad,
  ThreeText,
  ThreeTransdimensionalRect,
  TransdimensionalOffset,
  TransdimensionalRect,
} from '../..';
import {
  LIGHT_THEME,
  DARK_THEME,
  CONTACT_EMAIL_ADDRESS,
  CONTACT_TELEPHONE_NUMBER,
  THREE_FULL_DEPTH,
  THREE_FULL_HEIGHT,
  THREE_FULL_WIDTH,
  BRAND_COLOR_YELLOW,
  BRAND_COLOR_YELLOW_CONTRAST,
  SERVICE_ITEMS_BY_ID,
} from '../../../constants';
import { useInvertedTheme, useMaterial } from '../../../hooks';
import { DocumentSize } from '../../../types';
import {
  emailAddressToHref,
  enhance,
  getColorFilter,
  telephoneNumberToHref,
  toColor,
} from '../../../utilities';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { Box3, Vector3 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { useSelector } from 'react-redux';
import { getOrCreateTransdimensionalRectSelector } from '../../../selectors';
import {
  ResumeContainer,
  ResumeSection,
  ResumeSectionLabel,
  ResumeDivider,
  ResumeHero,
  ResumeHeroThree,
  ResumeBiography,
  ResumeBiographyThree,
  ResumeMeet,
  ResumeMeetThree,
  ResumeEducationSection,
  ResumeSkillsSection,
  ResumeSkillsSectionThree,
} from '.';

export const RESUME_DOCUMENT_SIZE: DocumentSize = {
  width: '8.5in',
  height: '11in',
  aspectRatio: '8.5 / 11',
};

const ResumeThree: FC<
  PropsWithChildren<{
    pageNumber: number;
  }>
> = ({ pageNumber, children }) => {
  return (
    <Three
      id={`resumeDocument_three${pageNumber}`}
      sx={{ position: 'absolute', width: '100%', height: '100%' }}
      fovScaling={false}
      frameloop="demand"
    >
      <ambientLight intensity={0.875} />
      <directionalLight color="white" position={[10, 5, 7.5]} intensity={0.4} />
      <TransdimensionalUnitConversionServiceContext.Consumer>
        {(transdimensionalUnitConversionService) => {
          if (!transdimensionalUnitConversionService) return null;

          const sceneWidth =
            transdimensionalUnitConversionService.getVisibleWidthAtDepth(0);
          const sceneHeight =
            transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);

          return <group position={[0, sceneHeight / 2, 0]}>{children}</group>;
        }}
      </TransdimensionalUnitConversionServiceContext.Consumer>
    </Three>
  );
};

const ResumeList: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      component="ul"
      className="ResumeList-root"
      sx={{
        position: 'relative',
        paddingLeft: 2,
        margin: 0,
        listStyle: 'none',
        '& .ResumeList-root': {
          '&::before': {
            content: "''",
            position: 'absolute',
            left: `calc(${theme.spacing(-2)} + 2px)`,
            top: -12,
            width: 2,
            height: 'calc(100% - 2px)',
            backgroundColor: theme.palette.text.secondary,
          },
          '& > .ResumeListItem-root': {
            '&::before': {
              content: "''",
              position: 'absolute',
              left: `calc(${theme.spacing(-4)} + 2px)`,
              top: 16,
              width: `calc(${theme.spacing(2)} - 2px)`,
              height: 2,
              backgroundColor: theme.palette.text.secondary,
            },
            '&::after': {
              content: "''",
              position: 'absolute',
              left: theme.spacing(-2),
              top: 14,
              width: 6,
              height: 6,
              backgroundColor: 'transparent',
              border: '2px solid',
              borderColor: theme.palette.text.secondary,
            },
          },
        },
        '& > .ResumeListItem-root': {
          '&::after': {
            content: "''",
            position: 'absolute',
            left: theme.spacing(-2),
            top: 14,
            width: 6,
            height: 6,
            backgroundColor: theme.palette.text.secondary,
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

const ResumeListItem: FC<PropsWithChildren<{ label: ReactNode }>> = ({
  label,
  children,
}) => {
  return (
    <Box
      component="li"
      className="ResumeListItem-root"
      sx={{ position: 'relative' }}
    >
      <Typography component="span" variant="body1">
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const ResumeExperienceItem: FC<{
  id: string;
  organization: ReactNode;
  location: ReactNode;
  time: ReactNode;
  role: ReactNode;
  description: ReactNode;
}> = (props) => {
  return (
    <TransdimensionalRect
      id={`resumeDocument_experienceItem_${props.id}`}
      display="block"
      sx={{ padding: 0 }}
    >
      <Typography component="span" variant="body1" fontWeight="bold">
        {props.organization}
      </Typography>
      <Typography component="span" variant="body2">
        {' '}
        — {props.location} — {props.time}
      </Typography>
      <Typography variant="body1" fontWeight={600} fontStyle="italic">
        {props.role}
      </Typography>
      {props.description}
    </TransdimensionalRect>
  );
};

export const ResumeDocument: FC = () => {
  const theme = useTheme();
  const invertedTheme = useInvertedTheme();
  const dividerColor = theme.palette.background.default;
  const matchesPrint = useMediaQuery('print');
  const basicMaterial1 = useMaterial(theme.palette.background.object, {
    convertSRGBToLinear: true,
  });

  return (
    <ThemeProvider theme={theme}>
      <DocumentPage
        documentSize={RESUME_DOCUMENT_SIZE}
        pageNumber={1}
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.background.clear,
        }}
      >
        <ResumeThree pageNumber={1}>
          {/* <ThreeQuad
            scale={[THREE_FULL_WIDTH, THREE_FULL_HEIGHT, 1]}
            position={[0, 0, -THREE_FULL_DEPTH / 2]}
            rotation={[Math.PI / 2, 0, 0]}
          /> */}
          <ResumeHeroThree />
          <ResumeBiographyThree />
          <ResumeMeetThree />
          <ResumeSkillsSectionThree />
          <ThreeTransdimensionalRect id="resumeDocument_page1_sideContentTop">
            <ThreeBox
              position={[-1 / 2, -1 / 2, -theme.depth.card / 2]}
              scale={[2, 2, theme.depth.card]}
              rotation={[0, 0, 0]}
              material={basicMaterial1}
            />
          </ThreeTransdimensionalRect>
          <ThreeTransdimensionalRect id="resumeDocument_page1_sideContent">
            <ThreeBox
              position={[-1 / 2, -1 / 2, -theme.depth.card / 2]}
              scale={[2, 2, theme.depth.card]}
              rotation={[0, 0, 0]}
              material={basicMaterial1}
            />
          </ThreeTransdimensionalRect>
        </ResumeThree>
        <ResumeHero />
        <ResumeBiography />
        <br />
        <ResumeContainer>
          <Box component="div" sx={{ position: 'relative' }}>
            <TransdimensionalRect
              id="resumeDocument_page1_sideContentTop"
              sx={{
                position: 'absolute',
                left: theme.spacing(-4),
                top: 0,
                width: `calc(${theme.spacing(4)} + 70px)`,
                height: '100%',
              }}
            />
            <ResumeMeet />
            <TransdimensionalRect
              id="resumeDocument_page1_sideContent"
              sx={{
                position: 'absolute',
                left: theme.spacing(-4),
                top: 0,
                width: `calc(${theme.spacing(4)} + 70px)`,
                height: '100%',
              }}
            />
            <ResumeEducationSection />
            <ResumeSkillsSection />
            <ResumeSection
              sideContent={
                <ResumeSectionLabel>
                  <NoWrap>Work Experience</NoWrap>
                </ResumeSectionLabel>
              }
            >
              <Stack spacing={1}>
                <ResumeExperienceItem
                  id="ryanBrothersAmbulance"
                  organization="Ryan Brothers Ambulance"
                  location="Madison, WI"
                  time="February 2024 to Present"
                  role="Paramedic"
                  description={
                    <ResumeList>
                      <ResumeListItem label="Administering basic and advanced life support to patients in pre-hospital and inter-facility settings." />
                      <ResumeListItem label="Utilizing advanced medical equipment including IV pumps, Vapotherm, BiPap, and blood products in providing patient care." />
                      <ResumeListItem label="Assisting critical care paramedics with specialty care transport services for high acuity patients." />
                    </ResumeList>
                  }
                />
                <ResumeExperienceItem
                  id="crossPlainsEms"
                  organization="Cross Plains EMS"
                  location="Cross Plains, WI"
                  time="June 2023 to Present"
                  role="Emergency Medical Technician"
                  description={
                    <ResumeList>
                      <ResumeListItem
                        label="Responding to 911 calls and providing emergency
                          medical care as an ambulance crew member with an AEMT
                          level service."
                      />
                      <ResumeListItem
                        label="Assisting with department needs such as training,
                          recruitment, community outreach, CAD technology
                          support, and ambulance improvements."
                      />
                    </ResumeList>
                  }
                />
              </Stack>
            </ResumeSection>
          </Box>
        </ResumeContainer>
        <Box component="div" sx={{ color: theme.palette.text.secondary }}>
          <ArrowDownwardOutlinedIcon
            fontSize="large"
            color="inherit"
            sx={{
              position: 'absolute',
              left: `calc(${theme.spacing(4)} + 7px)`,
              bottom: theme.spacing(2),
            }}
          />
        </Box>
      </DocumentPage>
      <DocumentPage
        documentSize={RESUME_DOCUMENT_SIZE}
        pageNumber={2}
        sx={{
          backgroundColor: theme.palette.background.clear,
        }}
      >
        <ResumeThree pageNumber={2}>
          <ThreeTransdimensionalRect id="resumeDocument_page2_sideContent">
            <ThreeBox
              position={[-1 / 2, 1 / 2, -theme.depth.card / 2]}
              scale={[2, 4, theme.depth.card]}
              rotation={[0, 0, 0]}
              material={basicMaterial1}
            />
          </ThreeTransdimensionalRect>
        </ResumeThree>
        <Box component="div" sx={{ color: theme.palette.text.secondary }}>
          <ArrowDownwardOutlinedIcon
            fontSize="large"
            color="inherit"
            sx={{
              position: 'absolute',
              left: `calc(${theme.spacing(4)} + 7px)`,
              top: theme.spacing(2),
            }}
          />
        </Box>
        <ResumeContainer>
          <Box component="div" sx={{ position: 'relative' }}>
            <TransdimensionalRect
              id="resumeDocument_page2_sideContent"
              sx={{
                position: 'absolute',
                left: theme.spacing(-4),
                top: 0,
                width: `calc(${theme.spacing(4)} + 70px)`,
                height: '100%',
              }}
            />
            <ResumeSection sideContent={null}>
              <Stack spacing={1}>
                <ResumeExperienceItem
                  id="dottid"
                  organization="Dottid"
                  location="Remote"
                  time="June 2022 to March 2023"
                  role="Senior Software Engineer"
                  description={
                    <ResumeList>
                      <ResumeListItem
                        label="Acted as lead frontend software engineer for a data
                          visualization suite developed for the commercial real
                          estate industry."
                      />
                      {/* <ResumeListItem
                        label="Mentored junior engineers and interviewed engineering
                          candidates."
                      /> */}
                    </ResumeList>
                  }
                />
                <ResumeExperienceItem
                  id="undigital"
                  organization="UnDigital"
                  location="Remote"
                  time="June 2021 to March 2022"
                  role="Software Engineer"
                  description={
                    <ResumeList>
                      <ResumeListItem
                        label="Worked with an early-revenue startup engineering team
                          to develop the company's software controlled on-demand
                          printed marketing product."
                      />
                    </ResumeList>
                  }
                />
                <ResumeExperienceItem
                  id="gettyImages"
                  organization="Getty Images"
                  location="Madison, WI"
                  time="June 2019 to March 2021"
                  role="Software Engineer"
                  description={
                    <ResumeList>
                      <ResumeListItem
                        label="As a member of the Content Ingestion software
                          development team, engineered software features used in
                          uploading and storing images and videos."
                      />
                      {/* <ResumeListItem
                        label="Interfaced with field editors and contributing
                          photographers to determine project requirements."
                      /> */}
                    </ResumeList>
                  }
                />
                <ResumeExperienceItem
                  id="hardinDesignAndDevelopment"
                  organization="Hardin Design and Development"
                  location="Madison, WI"
                  time="August 2018 to March 2019"
                  role="Web Applications Developer"
                  description={
                    <ResumeList>
                      <ResumeListItem
                        label="Developed and maintained web applications used by
                          major commercial real estate and insurance firms,
                          working as a consultant to identify and meet project
                          goals."
                      />
                    </ResumeList>
                  }
                />
              </Stack>
              <ResumeDivider />
            </ResumeSection>
            <ResumeSection
              sideContent={
                <ResumeSectionLabel>Certifications</ResumeSectionLabel>
              }
            >
              <ResumeList>
                <ResumeListItem label="National Registry of Emergency Medical Technicians">
                  <ResumeList>
                    <ResumeListItem label="Nationally Registered Paramedic" />
                  </ResumeList>
                </ResumeListItem>
                <ResumeListItem label="American Heart Association">
                  <ResumeList>
                    <ResumeListItem label="Basic Life Support (BLS)" />
                    <ResumeListItem label="Advanced Cardiovascular Life Support (ACLS)" />
                    <ResumeListItem label="Pediatric Advanced Life Support (PALS)" />
                  </ResumeList>
                </ResumeListItem>
                <ResumeListItem label="National Association of Emergency Medical Technicians">
                  <ResumeList>
                    <ResumeListItem label="Pre-Hospital Trauma Life Support (PHTLS)" />
                  </ResumeList>
                </ResumeListItem>
                <ResumeListItem label="FEMA Emergency Management Institute">
                  <ResumeList>
                    <ResumeListItem label="IS-00100.b - Introduction to Incident Command System" />
                    <ResumeListItem label="IS-00100.c - Introduction to Incident Command System" />
                    <ResumeListItem label="IS-00200.c - Basic Incident Command System for Initial Response" />
                    <ResumeListItem label="IS-00700.a - National Incident Management System (NIMS), An Introduction" />
                    <ResumeListItem label="IS-00700.b - An Introduction to the National Incident Management System" />
                    <ResumeListItem label="IS-00800.d - National Response Framework, An Introduction" />
                  </ResumeList>
                </ResumeListItem>
                {/* <ResumeListItem label="Coaching the Emergency Vehicle Operator (CEVO 4)" /> */}
              </ResumeList>
            </ResumeSection>
          </Box>
        </ResumeContainer>
      </DocumentPage>
    </ThemeProvider>
  );
};
