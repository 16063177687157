import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { TransdimensionalRect } from '../..';
import { ResumeSection, ResumeSectionLabel, ResumeDivider } from '.';

const ResumeEducationItem: FC<{
  id: string;
  institution: ReactNode;
  degree: ReactNode;
  completion: ReactNode;
}> = (props) => {
  return (
    <TransdimensionalRect
      id={`resumeDocument_educationItem_${props.id}`}
      display="block"
      sx={{ padding: 0 }}
    >
      <Typography component="span" variant="body1" fontWeight="bold">
        {props.institution}
      </Typography>
      <Typography component="span" variant="body2">
        {' '}
        — {props.completion}
      </Typography>
      <Typography variant="body1">{props.degree}</Typography>
    </TransdimensionalRect>
  );
};

export const ResumeEducationSection: FC = () => {
  return (
    <ResumeSection
      sideContent={<ResumeSectionLabel>Education</ResumeSectionLabel>}
    >
      <Stack spacing={1}>
        <ResumeEducationItem
          id="universityOfWisconsin"
          institution="University of Wisconsin - Madison"
          degree="Bachelor of Science, Computer Sciences"
          completion="May 2018"
        />
        <ResumeEducationItem
          id="madisonCollege"
          institution="Madison Area Technical College"
          degree="Paramedic Technical Diploma"
          completion="December 2023"
        />
      </Stack>
      <ResumeDivider />
    </ResumeSection>
  );
};
