import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useTransdimensionalOffsetLink } from '../../hooks';
import { TransdimensionalOffsetOptions } from '../../types';

export const TransdimensionalOffset: FC<
  PropsWithChildren<
    TransdimensionalOffsetOptions & {
      display?: 'block' | 'inline-block';
    }
  >
> = (props) => {
  const transformElementRef = useRef<HTMLDivElement>(null);
  const { link, unlink } = useTransdimensionalOffsetLink({
    containerRect: props.containerRect,
    z: props.z,
    handleTransform: (transform) => {
      if (!transformElementRef.current) return;

      transformElementRef.current.style.transform = transform;
    },
  });

  useEffect(() => {
    return unlink;
  }, [unlink]);

  return (
    <div
      ref={(element) => link(element)}
      style={{
        display: props.display || 'inline-block',
      }}
    >
      <div ref={transformElementRef}>{props.children}</div>
    </div>
  );
};
