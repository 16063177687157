import { ReactNode } from 'react';
import { Vector3, Color } from 'three';
import { Breakpoint, createTheme, darken, lighten } from '@mui/material';
import {
  ThemeOptions,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import {
  CalendlyPageSettings,
  AWSCertificationLevel,
  HashicorpCertificationLevel,
  HashicorpCertificationType,
  Home,
  HomeAccessory,
  HomeRoom,
  InquiryType,
  NavigationItem,
  ReferenceAsset3DConfiguration,
  ReferenceIndexItemConfiguration,
  ServiceItemSectionProps,
  ThreeModelTransformationPipelineParameters,
  ServiceItemProps,
} from './types';

export const DAYS_IN_WEEK = 7;

export const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const ABBREVIATED_DAY_NAMES = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const BRAND_COLOR_RED = 'rgb(255, 64, 0)';
export const BRAND_COLOR_RED_CONTRAST = 'white';
export const BRAND_COLOR_ORANGE = 'rgb(255, 148, 32)';
export const BRAND_COLOR_ORANGE_CONTRAST = 'white';
export const BRAND_COLOR_YELLOW = 'rgb(238, 238, 64)';
export const BRAND_COLOR_YELLOW_LIGHT = lighten(BRAND_COLOR_YELLOW, 0.2);
export const BRAND_COLOR_YELLOW_MAIN = BRAND_COLOR_YELLOW;
export const BRAND_COLOR_YELLOW_DARK = darken(BRAND_COLOR_YELLOW, 0.075);
export const BRAND_COLOR_YELLOW_CONTRAST = 'black';
export const BRAND_COLOR_GREEN = 'rgb(96, 184, 16)';
export const BRAND_COLOR_GREEN_CONTRAST = 'black';

export const DARK_PRIMARY_TEXT_COLOR = 'rgb(64, 64, 64)';

export const EMPTY_VALUE_INDICATOR = '-';
export const CONTAINER_WIDTH = 720;

export const BREAKPOINTS: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

export const BREAKPOINT_WIDTHS = new Map<Breakpoint, number>([
  ['xs', 0],
  ['sm', 600],
  ['md', 900],
  ['lg', 1200],
  ['xl', 1536],
]);

export const THEME_OPTIONS: Partial<ThemeOptions> = {
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            lineHeight: '2',
            fontSize: '3.5rem',
            fontWeight: 800,
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            lineHeight: '2',
            fontSize: '2.9rem',
            fontWeight: 800,
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            lineHeight: '2',
            fontSize: '1.75rem',
            fontWeight: 700,
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            lineHeight: '2',
            fontSize: '1.75rem',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            lineHeight: '2',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            lineHeight: '2',
            textTransform: 'uppercase',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'code' },
          style: {
            fontFamily: '"Lucida Console", Monaco, monospace',
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          verticalAlign: 'top',
          fontSize: '1rem',
          lineHeight: '2',
          borderBottom: 'none',
        },
      },
      variants: [
        {
          props: { component: 'th' },
          style: {
            textTransform: 'uppercase',
            color: 'rgb(128, 128, 128)',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&::before': {
            content: "''",
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 1,
            backgroundColor: 'rgba(128, 128, 128, 0.5)',
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    secondary: {
      light: BRAND_COLOR_YELLOW_LIGHT,
      main: BRAND_COLOR_YELLOW_MAIN,
      dark: BRAND_COLOR_YELLOW_DARK,
      contrastText: DARK_PRIMARY_TEXT_COLOR,
    },
    brandRed: {
      main: BRAND_COLOR_RED,
      contrastText: BRAND_COLOR_RED_CONTRAST,
    },
    brandOrange: {
      main: BRAND_COLOR_ORANGE,
      contrastText: BRAND_COLOR_ORANGE_CONTRAST,
    },
    brandYellow: {
      light: BRAND_COLOR_YELLOW_LIGHT,
      main: BRAND_COLOR_YELLOW_MAIN,
      dark: BRAND_COLOR_YELLOW_DARK,
      contrastText: DARK_PRIMARY_TEXT_COLOR,
    },
  },
  depth: {
    paper: 0.5,
    card: 0.25,
    button: 0.125,
    chip: 0.1,
    input: 0.1,
  },
};

export const LIGHT_THEME = createTheme({
  ...THEME_OPTIONS,
  palette: {
    ...THEME_OPTIONS.palette,
    mode: 'light',
    text: {
      primary: DARK_PRIMARY_TEXT_COLOR,
      secondary: 'rgb(128, 128, 128)',
      disabled: 'rgb(192, 192, 192)',
    },
    primary: {
      main: 'rgb(64, 64, 64)',
      contrastText: 'rgb(255, 255, 255)',
    },
    background: {
      clear: 'rgb(255, 255, 255)',
      paper: 'rgb(240, 240, 240)',
      default: 'rgb(224, 224, 224)',
      object: 'rgb(224, 224, 224)',
      chip: 'rgb(64, 64, 64)',
    },
    intensityOffset: 0.1,
  },
  components: {
    ...THEME_OPTIONS.components,
    MuiTypography: {
      ...THEME_OPTIONS.components?.MuiTypography,
      variants: THEME_OPTIONS.components?.MuiTypography?.variants?.map(
        (variant) => {
          switch (variant.props.variant) {
            case 'body2':
              return {
                ...variant,
                style: Object.assign({}, variant.style, {
                  color: 'rgb(128, 128, 128)',
                }),
              };
          }

          return variant;
        }
      ),
    },
  },
});

export const DARK_THEME = createTheme({
  ...THEME_OPTIONS,
  palette: {
    ...THEME_OPTIONS.palette,
    mode: 'dark',
    text: {
      primary: 'rgb(255, 255, 255)',
      secondary: 'rgb(192, 192, 192)',
      disabled: 'rgb(184, 184, 184)',
    },
    primary: {
      main: 'rgb(255, 255, 255)',
      contrastText: 'rgb(64, 64, 64)',
    },
    background: {
      clear: 'rgb(32, 32, 32)',
      paper: 'rgb(84, 84, 84)',
      default: 'rgb(84, 84, 84)',
      object: 'rgb(48, 48, 48)',
      chip: 'rgb(224, 224, 224)',
    },
    intensityOffset: 0.2,
  },
  components: {
    ...THEME_OPTIONS.components,
    MuiTypography: {
      ...THEME_OPTIONS.components?.MuiTypography,
      variants: THEME_OPTIONS.components?.MuiTypography?.variants?.map(
        (variant) => {
          switch (variant.props.variant) {
            case 'body2':
              return {
                ...variant,
                style: Object.assign({}, variant.style, {
                  color: 'rgb(192, 192, 192)',
                }),
              };
          }

          return variant;
        }
      ),
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgb(255, 255, 255)',
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.26)',
          },
        },
      },
    },
  },
});

export const EXTENDED_THEME = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: LIGHT_THEME.palette.primary,
        secondary: LIGHT_THEME.palette.secondary,
        background: LIGHT_THEME.palette.background,
        brandRed: LIGHT_THEME.palette.brandRed,
        brandOrange: LIGHT_THEME.palette.brandOrange,
        brandYellow: LIGHT_THEME.palette.brandYellow,
      },
    },
    dark: {
      palette: {
        primary: DARK_THEME.palette.primary,
        secondary: DARK_THEME.palette.secondary,
        background: DARK_THEME.palette.background,
        brandRed: DARK_THEME.palette.brandRed,
        brandOrange: DARK_THEME.palette.brandOrange,
        brandYellow: DARK_THEME.palette.brandYellow,
      },
    },
  },
});

export const CONTACT_TELEPHONE_NUMBER = '+1 (203) 501-2538';
export const CONTACT_EMAIL_ADDRESS = 'sam@mangane.se';

export type MeetingLocation =
  | 'google-meet'
  | 'microsoft-teams'
  | 'zoom'
  | 'phone';

export const MEETING_LOCATIONS = new Set<MeetingLocation>([
  'google-meet',
  'microsoft-teams',
  'zoom',
  'phone',
]);

export const VIDEO_MEETING_LOCATIONS = new Set<MeetingLocation>([
  'google-meet',
  'microsoft-teams',
  'zoom',
]);

export const PHONE_MEETING_LOCATIONS = new Set<MeetingLocation>(['phone']);

export const MEETING_LOCATION_LABELS: Map<MeetingLocation, string> = new Map([
  ['google-meet', 'Google Meet'],
  ['microsoft-teams', 'Microsoft Teams'],
  ['zoom', 'Zoom'],
  ['phone', 'Phone'],
]);

export const GOOGLE_MEET_COLOR = 'rgb(6, 171, 71)';
export const MICROSOFT_TEAMS_COLOR = 'rgb(76, 86, 196)';
export const ZOOM_COLOR = 'rgb(0, 137, 237)';

export const DEFAULT_CALENDLY_PROXY_URL = '/api/calendly-proxy';
export const CALENDLY_INTRODUCTION_EVENT_TYPE_URI =
  'https://api.calendly.com/event_types/71821e9d-99ba-4eee-92d8-cef4898aa662';
export const CALENDLY_PAGE_SETTINGS: CalendlyPageSettings = {
  hideLandingPageDetails: true,
  hideEventTypeDetails: true,
  hideGdprBanner: true,
};

export const LAYOUT_POINT_VARIABLE_SUFFIX_X = '_x';
export const LAYOUT_POINT_VARIABLE_SUFFIX_Y = '_y';

export const LAYOUT_ANCHOR_ID_SERVICES_TOP = 'services_top';
export const LAYOUT_ANCHOR_ID_SERVICES_BOTTOM = 'services_bottom';
export const LAYOUT_ANCHOR_ID_SERVICES_SIDE_LEFT = 'services_sideLeft';
export const LAYOUT_ANCHOR_ID_SERVICES_SIDE_RIGHT = 'services_sideRight';
export const LAYOUT_ANCHOR_ID_SERVICES_CONTENT_LEFT = 'services_contentLeft';
export const LAYOUT_ANCHOR_ID_SERVICES_CONTENT_RIGHT = 'services_contentRight';
export const LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_LEFT =
  'services_develop_heading_left';
export const LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_HEADING_RIGHT =
  'services_develop_heading_right';
export const LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_TOP =
  'services_develop_body_top';
export const LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_BOTTOM =
  'services_develop_body_bottom';
export const LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_LEFT =
  'services_design_heading_left';
export const LAYOUT_ANCHOR_ID_SERVICES_DESIGN_HEADING_RIGHT =
  'services_design_heading_right';
export const LAYOUT_ANCHOR_ID_SERVICES_DESIGN_BODY_TOP =
  'services_design_body_top';
export const LAYOUT_ANCHOR_ID_SERVICES_DESIGN_BODY_BOTTOM =
  'services_design_body_bottom';
export const LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_LEFT =
  'services_deploy_heading_left';
export const LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_HEADING_RIGHT =
  'services_deploy_heading_right';
export const LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_BODY_TOP =
  'services_deploy_body_top';
export const LAYOUT_ANCHOR_ID_SERVICES_DEPLOY_BODY_BOTTOM =
  'services_deploy_body_bottom';

export const PERSISTENCE_KEY_DEBUG_MODE = 'debugMode';

export const THREE_CAMERA_DEPTH = 5;
export const THREE_DEFAULT_FOV = 50;
export const THREE_DEFAULT_NEAR = 4;
export const THREE_DEFAULT_FAR = 100;
export const THREE_DEFAULT_FOG_NEAR = 5;
export const THREE_DEFAULT_FOG_FAR = 10;
export const THREE_FULL_WIDTH = 1000;
export const THREE_FULL_HEIGHT = 1000;
export const THREE_FULL_DEPTH = 1000;
export const THREE_DEFAULT_TRANSFORMATION_PIPELINE = ['standardizeMaterials'];
export const THREE_MODELS = new Map<
  string /* model ID */,
  {
    url: string;
    transform: ThreeModelTransformationPipelineParameters;
  }
>([
  // Primitives
  [
    'wedge',
    {
      url: '/models/wedge.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],

  // Pipes
  [
    'pipe',
    {
      url: '/models/pipe.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'pipe_corner',
    {
      url: '/models/pipe_corner.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'pipe_attachment',
    {
      url: '/models/pipe_attachment.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'pipe_vent_double',
    {
      url: '/models/pipe_vent_double.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'pipe_valve_handle',
    {
      url: '/models/pipe_valve_handle.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'pipe_block',
    {
      url: '/models/pipe_block.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],

  // Trusses
  [
    'truss_cube_fulltruss',
    {
      url: '/models/truss_cube_fulltruss.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'truss_cube_halftruss',
    {
      url: '/models/truss_cube_halftruss.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'truss_cube_notruss',
    {
      url: '/models/truss_cube_notruss.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'truss_cubechain_fulltruss',
    {
      url: '/models/truss_cubechain_fulltruss.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'truss_cubechain_halftruss',
    {
      url: '/models/truss_cubechain_halftruss.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'truss_cubechain_notruss',
    {
      url: '/models/truss_cubechain_notruss.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],

  // Documents
  [
    'quotation_marks',
    {
      url: '/models/quotation_marks.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],

  // Certification - AWS
  [
    'certification_aws',
    {
      url: '/models/certification_aws.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'certification_aws_negative_layer1',
    {
      url: '/models/certification_aws_negative_layer1.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
  [
    'certification_aws_negative_layer2',
    {
      url: '/models/certification_aws_negative_layer2.glb',
      transform: THREE_DEFAULT_TRANSFORMATION_PIPELINE,
    },
  ],
]);
export const THREE_FONTS = new Map<
  string /* font ID */,
  {
    url: string;
  }
>([
  [
    'OpenSans_ExtraBold',
    {
      url: '/fonts/OpenSans_ExtraBold_Limited.json',
    },
  ],
]);

export const PAGE_SIDE_WIDTH = 240;

export const LOGO_SVG_WIDTH = 152;
export const LOGO_SVG_HEIGHT = 96;

export const SERVICES_THREE_LIGHT_POSITION = new Vector3(10, 30, 10);
export const SERVICES_THREE_LIGHT_INTENSITY = 1;

// Service items
export const SERVICE_ITEMS_SECTION_ID_PREFIX = 'service-items-section-';
export const SERVICE_ITEM_ID_PREFIX = 'service-item-';
export const SERVICE_ITEMS_BY_ID: Record<string, ServiceItemProps> = {
  ruby_on_rails: {
    id: 'ruby_on_rails',
    iconUrl: '/icons/rails.png',
    label: 'Ruby on Rails',
  },
  elixir: {
    id: 'elixir',
    iconUrl: '/icons/elixir.png',
    label: 'Elixir',
  },
  node: {
    id: 'node',
    iconUrl: '/icons/node.png',
    label: 'Node.js',
  },
  react: {
    id: 'react',
    iconUrl: '/icons/react.png',
    label: 'React',
  },
  redux: {
    id: 'redux',
    iconUrl: '/icons/redux.png',
    label: 'Redux',
  },
  typescript: {
    id: 'typescript',
    iconUrl: '/icons/typescript.png',
    label: 'TypeScript',
  },
  webpack: {
    id: 'webpack',
    iconUrl: '/icons/webpack.png',
    label: 'Webpack',
  },
  three: {
    id: 'three',
    iconUrl: '/icons/three.png',
    label: 'Three.js',
  },
  ionic: {
    id: 'ionic',
    iconUrl: '/icons/ionic.png',
    label: 'Ionic',
    badge: 'Contributor',
    badgeBackground: BRAND_COLOR_YELLOW,
    badgeColor: BRAND_COLOR_YELLOW_CONTRAST,
  },
  react_native: {
    id: 'react_native',
    iconUrl: '/icons/react.png',
    label: 'React Native',
  },
  swift: {
    id: 'swift',
    iconUrl: '/icons/swift.png',
    label: 'Swift',
  },
  unity: {
    id: 'unity',
    iconUrl: '/icons/unity.png',
    label: 'Unity',
  },
  graphql: {
    id: 'graphql',
    iconUrl: '/icons/graphql.png',
    label: 'GraphQL',
  },
  protobuf: {
    id: 'protobuf',
    iconUrl: '/icons/protobuf.png',
    label: 'Protobuf',
  },
  figma: {
    id: 'figma',
    iconUrl: '/icons/figma.png',
    label: 'Figma',
  },
  blender: {
    id: 'blender',
    iconUrl: '/icons/blender.png',
    label: 'Blender',
  },
  gimp: {
    id: 'gimp',
    iconUrl: '/icons/gimp.png',
    label: 'GIMP',
  },
  photoshop: {
    id: 'photoshop',
    iconUrl: '/icons/photoshop.png',
    label: 'Adobe Photoshop',
  },
  amazon_web_services: {
    id: 'amazon_web_services',
    iconUrl: '/icons/aws.png',
    label: 'AWS',
    badge: 'Certified x 2',
    badgeBackground: BRAND_COLOR_YELLOW,
    badgeColor: BRAND_COLOR_YELLOW_CONTRAST,
  },
  google_cloud: {
    id: 'google_cloud',
    iconUrl: '/icons/gcp.png',
    label: 'Google Cloud',
  },
  terraform: {
    id: 'terraform',
    iconUrl: '/icons/terraform.png',
    label: 'Terraform',
    badge: 'Certified',
    badgeBackground: BRAND_COLOR_YELLOW,
    badgeColor: BRAND_COLOR_YELLOW_CONTRAST,
  },
  packer: {
    id: 'packer',
    iconUrl: '/icons/packer.png',
    label: 'Packer',
  },
  gitlab: {
    id: 'gitlab',
    iconUrl: '/icons/gitlab.png',
    label: 'GitLab',
  },
  circle_ci: {
    id: 'circle_ci',
    iconUrl: '/icons/circle-ci.png',
    label: 'CircleCI',
  },
  docker: {
    id: 'docker',
    iconUrl: '/icons/docker.png',
    label: 'Docker',
  },
  kubernetes: {
    id: 'kubernetes',
    iconUrl: '/icons/kubernetes.png',
    label: 'Kubernetes',
  },
  nomad: {
    id: 'nomad',
    iconUrl: '/icons/nomad.png',
    label: 'Nomad',
  },
  vault: {
    id: 'vault',
    iconUrl: '/icons/vault.png',
    label: 'Vault',
    badge: 'Certified',
    badgeBackground: BRAND_COLOR_YELLOW,
    badgeColor: BRAND_COLOR_YELLOW_CONTRAST,
  },
};
export const SERVICES_DEVELOP_SERVICE_ITEM_SECTIONS: ServiceItemSectionProps[] =
  [
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'backend-development',
      label: 'Backend Development',
      items: [
        SERVICE_ITEMS_BY_ID['ruby_on_rails'],
        SERVICE_ITEMS_BY_ID['elixir'],
        SERVICE_ITEMS_BY_ID['node'],
      ],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'web-development',
      label: 'Web Development',
      items: [
        SERVICE_ITEMS_BY_ID['react'],
        SERVICE_ITEMS_BY_ID['redux'],
        SERVICE_ITEMS_BY_ID['typescript'],
        SERVICE_ITEMS_BY_ID['webpack'],
        SERVICE_ITEMS_BY_ID['three'],
      ],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'mobile-development',
      label: 'Mobile Development',
      items: [
        SERVICE_ITEMS_BY_ID['ionic'],
        SERVICE_ITEMS_BY_ID['react_native'],
        SERVICE_ITEMS_BY_ID['swift'],
      ],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'gaming-and-virtual-reality',
      label: 'Gaming & Virtual Reality',
      items: [SERVICE_ITEMS_BY_ID['unity']],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'data-interchange',
      label: 'Data Interchange',
      items: [SERVICE_ITEMS_BY_ID['graphql'], SERVICE_ITEMS_BY_ID['protobuf']],
    },
  ];
export const SERVICES_DESIGN_SERVICE_ITEM_SECTIONS: ServiceItemSectionProps[] =
  [
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'user-interfaces',
      label: 'User Interfaces',
      items: [SERVICE_ITEMS_BY_ID['figma']],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + '3d-assets',
      label: '3D Assets',
      items: [SERVICE_ITEMS_BY_ID['blender']],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + '2d-assets',
      label: '2D Assets',
      items: [SERVICE_ITEMS_BY_ID['gimp']],
    },
  ];

export const SERVICES_DEPLOY_SERVICE_ITEM_SECTIONS: ServiceItemSectionProps[] =
  [
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'cloud',
      label: 'Cloud',
      items: [
        SERVICE_ITEMS_BY_ID['amazon_web_services'],
        SERVICE_ITEMS_BY_ID['google_cloud'],
      ],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'infrastructure-as-code',
      label: 'Infrastructure As Code',
      items: [SERVICE_ITEMS_BY_ID['terraform'], SERVICE_ITEMS_BY_ID['packer']],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'continuous-integration',
      label: 'Continuous Integration',
      items: [SERVICE_ITEMS_BY_ID['gitlab'], SERVICE_ITEMS_BY_ID['circle_ci']],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'containerization',
      label: 'Containerization',
      items: [SERVICE_ITEMS_BY_ID['docker']],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'orchestration',
      label: 'Orchestration',
      items: [SERVICE_ITEMS_BY_ID['kubernetes'], SERVICE_ITEMS_BY_ID['nomad']],
    },
    {
      id: SERVICE_ITEMS_SECTION_ID_PREFIX + 'secret-management',
      label: 'Secret Management',
      items: [SERVICE_ITEMS_BY_ID['vault']],
    },
  ];

export const AWS_CERTIFICATION_LEVEL_COLORS = new Map<
  AWSCertificationLevel,
  string
>([
  [AWSCertificationLevel.Foundational, BRAND_COLOR_YELLOW],
  [AWSCertificationLevel.Associate, BRAND_COLOR_ORANGE],
  [AWSCertificationLevel.Professional, BRAND_COLOR_RED],
  [AWSCertificationLevel.Specialty, '#5F45B4'],
]);

export const AWS_CERTIFICATION_LEVEL_LABELS = new Map<
  AWSCertificationLevel,
  string
>([
  [AWSCertificationLevel.Foundational, 'Foundational'],
  [AWSCertificationLevel.Associate, 'Associate'],
  [AWSCertificationLevel.Professional, 'Professional'],
  [AWSCertificationLevel.Specialty, 'Specialty'],
]);

export const HASHICORP_CERTIFICATION_TYPE_LABELS = new Map<
  HashicorpCertificationType,
  ReactNode
>([
  [HashicorpCertificationType.Terraform, 'Terraform'],
  [HashicorpCertificationType.Vault, 'Vault'],
  [HashicorpCertificationType.Consul, 'Consul'],
]);

export const HASHICORP_CERTIFICATION_TYPE_ICONS = new Map<
  HashicorpCertificationType,
  string
>([
  [HashicorpCertificationType.Terraform, '/icons/terraform.png'],
  [HashicorpCertificationType.Vault, '/icons/vault.png'],
]);

export const HASHICORP_CERTIFICATION_TYPE_COLORS = new Map<
  HashicorpCertificationType,
  string
>([
  [HashicorpCertificationType.Terraform, BRAND_COLOR_YELLOW],
  [HashicorpCertificationType.Vault, '#082090'],
]);

export const HOMES: Home[] = [
  {
    id: 'prima',
    name: 'Prima',
    streetAddress: '5131 E Cheryl Pkwy\nApt 324\nFitchburg, WI 53711',
  },
];

export const HOME_ROOMS: HomeRoom[] = [
  {
    id: 'prima-entry',
    homeId: 'prima',
    name: 'Entry',
  },
  {
    id: 'prima-bedroom',
    homeId: 'prima',
    name: 'Bedroom',
  },
  {
    id: 'prima-closet',
    homeId: 'prima',
    name: 'Closet',
  },
  {
    id: 'prima-living-room',
    homeId: 'prima',
    name: 'Living Room',
  },
  {
    id: 'prima-kitchen',
    homeId: 'prima',
    name: 'Kitchen',
  },
  {
    id: 'prima-balcony',
    homeId: 'prima',
    name: 'Balcony',
  },
  {
    id: 'prima-office',
    homeId: 'prima',
    name: 'Office',
  },
  {
    id: 'prima-bathroom',
    homeId: 'prima',
    name: 'Bathroom',
  },
];

export const HOME_ACCESSORIES: HomeAccessory[] = [
  // Door locks
  {
    id: 'main-door-lock',
    homeRoomId: 'prima-entry',
    name: 'Main Door Lock',
    manufacturer: 'Level Home Inc.',
    model: 'Level Bolt',
    serial: 'A3A208PSH6',
    firmware: '2.27.3',
    category: 'doorLock',
    pairingCode: '814-55-765',
    setupId: 'WIWV',
    setupPayload: 'X-HM://0067BH1UDWIWV',
  },
  // Thermostats
  {
    id: 'main-thermostat',
    homeRoomId: 'prima-entry',
    name: 'Main Thermostat',
    manufacturer: 'ecobee Inc.',
    model: 'ECB601',
    serial: '531624707146',
    firmware: '4.7.630262',
    category: 'thermostat',
    pairingCode: '814-55-765',
    setupId: null,
    setupPayload: null,
  },
  // Bridges - Apple TVs
  {
    id: 'living-room-apple-tv',
    homeRoomId: 'prima-living-room',
    name: 'Living Room Apple TV',
    manufacturer: 'Apple Inc.',
    model: 'MP7P2LL/A',
    serial: 'C07C38LYHNM4',
    firmware: '16.4.1',
    category: 'appleTv',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'bedroom-apple-tv',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Apple TV',
    manufacturer: 'Apple Inc.',
    model: 'MR912LL/A',
    serial: 'C07WRZA6JG2X',
    firmware: '16.4.1',
    category: 'appleTv',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  // Bridges - HomePods
  {
    id: 'bedroom-homepod',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom HomePod',
    manufacturer: 'Apple Inc.',
    model: 'MQHW2',
    serial: 'DLXVLGGUHQK8',
    firmware: '16.4.1',
    category: 'homePod',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'kitchen-left-homepod-mini',
    homeRoomId: 'prima-kitchen',
    name: 'Kitchen Left HomePod Mini',
    manufacturer: 'Apple Inc.',
    model: 'MQHW2',
    serial: 'DLXVLGGUHQK8',
    firmware: '16.4.1',
    category: 'homePodMini',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'kitchen-right-homepod-mini',
    homeRoomId: 'prima-kitchen',
    name: 'Kitchen Right HomePod Mini',
    manufacturer: 'Apple Inc.',
    model: 'MY5H2LL/A',
    serial: 'H6JDKJVMPQ1J',
    firmware: '16.4.1',
    category: 'homePodMini',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'office-homepod',
    homeRoomId: 'prima-office',
    name: 'Office HomePod',
    manufacturer: 'Apple Inc.',
    model: 'MQHV2LL/A',
    serial: 'DLXVVD2UJ265',
    firmware: '16.4.1',
    category: 'homePod',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'bathroom-homepod',
    homeRoomId: 'prima-bathroom',
    name: 'Bathroom HomePod',
    manufacturer: 'Apple Inc.',
    model: 'MQHV2',
    serial: 'DLXW3N94J265',
    firmware: '16.4.1',
    category: 'homePod',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  // Speakers
  {
    id: 'bedroom-soundbar',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Soundbar',
    manufacturer: 'Bose',
    model: 'Bose Soundbar 500',
    serial: '078335P91201693AE',
    firmware: '17.0.15-5+bac1022',
    category: 'speaker',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'living-room-soundbar',
    homeRoomId: 'prima-living-room',
    name: 'Living Room Soundbar',
    manufacturer: 'Bose',
    model: 'Bose Soundbar 700',
    serial: '078321P92068590AE',
    firmware: '17.0.15-5+bac1022',
    category: 'speaker',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  // Window coverings
  {
    id: 'balcony-shade-right',
    homeRoomId: 'prima-balcony',
    name: 'Balcony Shade Right',
    manufacturer: 'Aqara',
    model: 'AM023',
    serial: '54ef44100044e887',
    firmware: '1530.0',
    category: 'windowCovering',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  // Sensors - Contact sensors
  // Sensors - Contact sensors - Doors
  {
    id: 'bedroom-door-contact-sensor',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Door Contact Sensor',
    manufacturer: 'Elgato',
    model: 'Eve Door 20EAL9901',
    serial: 'DV26H1A14159',
    firmware: '1.2.8',
    category: 'door',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'closet-door-contact-sensor',
    homeRoomId: 'prima-closet',
    name: 'Closet Door Contact Sensor',
    manufacturer: 'Elgato',
    model: 'Eve Door 20EAL9901',
    serial: 'DV51I1A05027',
    firmware: '1.2.8',
    category: 'door',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'balcony-door-contact-sensor',
    homeRoomId: 'prima-balcony',
    name: 'Balcony Door Contact Sensor',
    manufacturer: 'Elgato',
    model: 'Eve Door 20EAL9901',
    serial: 'DV46G1A10016',
    firmware: '1.2.8',
    category: 'slidingDoor',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'office-door-contact-sensor',
    homeRoomId: 'prima-office',
    name: 'Office Door Contact Sensor',
    manufacturer: 'Elgato',
    model: 'Eve Door 20EAL9901',
    serial: 'DV26H1A19241',
    firmware: '1.2.8',
    category: 'door',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  // Sensors - Contact sensors - Windows
  {
    id: 'bedroom-upper-window-contact-sensor',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Upper Window Contact Sensor',
    manufacturer: 'LUMI',
    model: 'lumi.sensor_magnet.aq2',
    serial: '00158D000806DA8B',
    firmware: '2016.11.28',
    category: 'window',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'bedroom-lower-window-contact-sensor',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Lower Window Contact Sensor',
    manufacturer: 'LUMI',
    model: 'lumi.sensor_magnet.aq2',
    serial: '00158D0007ED1490',
    firmware: '2016.11.28',
    category: 'window',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'living-room-east-upper-window-contact-sensor',
    homeRoomId: 'prima-living-room',
    name: 'Living Room East Upper Window Contact Sensor',
    manufacturer: 'LUMI',
    model: 'lumi.sensor_magnet.aq2',
    serial: '00158D0007EF462E',
    firmware: '2016.11.28',
    category: 'window',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'living-room-east-lower-window-contact-sensor',
    homeRoomId: 'prima-living-room',
    name: 'Living Room East Lower Window Contact Sensor',
    manufacturer: 'LUMI',
    model: 'lumi.sensor_magnet.aq2',
    serial: '00158D0007EF53C1',
    firmware: '2016.11.28',
    category: 'window',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'living-room-north-upper-window-contact-sensor',
    homeRoomId: 'prima-living-room',
    name: 'Living Room North Upper Window Contact Sensor',
    manufacturer: 'LUMI',
    model: 'lumi.sensor_magnet.aq2',
    serial: '00158D0007ED0E83',
    firmware: '2016.11.28',
    category: 'window',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'living-room-north-lower-window-contact-sensor',
    homeRoomId: 'prima-living-room',
    name: 'Living Room North Lower Window Contact Sensor',
    manufacturer: 'LUMI',
    model: 'lumi.sensor_magnet.aq2',
    serial: '00158D0007EF5933',
    firmware: '2016.11.28',
    category: 'window',
    pairingCode: null,
    setupId: null,
    setupPayload: null,
  },
  // Snesors - Contact sensors - Other
  {
    id: 'bedroom-left-headboard-contact-sensor',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Left Headboard Contact Sensor',
    manufacturer: 'Eve Systems',
    model: 'Eve Door 20EBN9901',
    serial: 'QV22L1A21982',
    firmware: '2.1.1',
    category: 'contactSensor',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'bedroom-right-headboard-contact-sensor',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Right Headboard Contact Sensor',
    manufacturer: 'Eve Systems',
    model: 'Eve Door 20EBN9901',
    serial: 'QV22L1A21983',
    firmware: '2.1.1',
    category: 'contactSensor',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'bedroom-entertainment-center-contact-sensor',
    homeRoomId: 'prima-bedroom',
    name: 'Bedroom Entertainment Center Contact Sensor',
    manufacturer: 'Eve Systems',
    model: 'Eve Door 20EBN9901',
    serial: 'QV45J1A00816',
    firmware: '2.1.1',
    category: 'contactSensor',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  // Sensors - Motion sensors
  {
    id: 'closet-dresser-motion-sensor',
    homeRoomId: 'prima-closet',
    name: 'Closet Dresser Motion Sensor',
    manufacturer: 'Elgato',
    model: 'Eve Motion 20EAK9901',
    serial: 'CV47G1A07247',
    firmware: '1.2.8',
    category: 'motionSensor',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
  {
    id: 'closet-wall-motion-sensor',
    homeRoomId: 'prima-closet',
    name: 'Closet Wall Motion Sensor',
    manufacturer: 'Elgato',
    model: 'Eve Motion 20EAK9901',
    serial: 'CV43J1A05869',
    firmware: '1.2.8',
    category: 'motionSensor',
    pairingCode: '111-22-333',
    setupId: null,
    setupPayload: null,
  },
];

export const REFERENCE_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 'overview',
    title: 'Overview',
    url: '/reference/overview',
  },
  {
    id: 'brand',
    title: 'Brand',
    url: '/reference/brand',
    children: [
      {
        id: 'brand/palette',
        title: 'Palette',
        url: '/reference/brand/palette',
      },
      {
        id: 'brand/gradient',
        title: 'Gradient',
        url: '/reference/brand/gradient',
      },
    ],
  },
  {
    id: 'projects',
    title: 'Projects',
    url: '/reference/projects',
  },
  {
    id: 'certifications',
    title: 'Certifications',
    url: '/reference/certifications',
  },
  {
    id: 'infrastructure',
    title: 'Infrastructure',
    url: '/reference/infrastructure',
    children: [
      {
        id: 'infrastructure/cloud',
        title: 'Cloud',
        url: '/reference/infrastructure/cloud',
        children: [
          {
            id: 'infrastructure/cloud/dns',
            title: 'Global DNS',
            url: '/reference/infrastructure/cloud#dns',
          },
          {
            id: 'infrastructure/cloud/images',
            title: 'Image registries',
            url: '/reference/infrastructure/cloud#images',
          },
          {
            id: 'infrastructure/cloud/images',
            title: 'Package registries',
            url: '/reference/infrastructure/cloud#packages',
          },
        ],
      },
      {
        id: 'infrastructure/services',
        title: 'Services',
        url: '/reference/infrastructure/services',
      },
      {
        id: 'infrastructure/local',
        title: 'Local',
        url: '/reference/infrastructure/local',
        children: [
          {
            id: 'infrastructure/local/dhcp',
            title: 'DHCP',
            url: '/reference/infrastructure/local#dhcp',
          },
          {
            id: 'infrastructure/local/dns',
            title: 'Local DNS',
            url: '/reference/infrastructure/local#dns',
          },
          {
            id: 'infrastructure/local/vpn',
            title: 'Local VPN',
            url: '/reference/infrastructure/local#vpn',
          },
          {
            id: 'infrastructure/local/proxy',
            title: 'Local Reverse Proxy',
            url: '/reference/infrastructure/local#proxy',
          },
        ],
      },
    ],
  },
];
export const REFERENCE_INDEX_ITEMS: ReferenceIndexItemConfiguration[] = [
  {
    id: 'brand',
    name: 'Brand',
    description: 'Brand identity stuff',
    url: '/reference/brand',
  },
  {
    id: 'projects',
    name: 'Projects',
    description: 'Project links stuff',
    url: '/reference/projects',
  },
  {
    id: 'infrastructure',
    name: 'Infrastructure',
    description: 'Infra stuff',
    url: '/reference/infrastructure',
  },
  {
    id: '2d-assets',
    name: '2D Assets',
    description: 'Two-dim assets and stuff',
    url: '/reference/2d-assets',
  },
  {
    id: '3d-assets',
    name: '3D Assets',
    description: 'Three-dim assets and stuff',
    url: '/reference/3d-assets',
  },
  {
    id: 'home-accessories',
    name: 'Home Accessories',
    description: 'Home accessories stuff',
    url: '/reference/home-accessories',
  },
];

export const REFERENCE_ASSETS_3D: ReferenceAsset3DConfiguration[] = [
  {
    id: 'pipe',
    name: 'Pipe',
  },
  {
    id: 'pipe_corner',
    name: 'Pipe Corner',
  },
  {
    id: 'pipe_block',
    name: 'Pipe Block',
  },
  {
    id: 'wedge',
    name: 'Wedge',
  },
  {
    id: 'truss_cube_fulltruss',
    name: 'truss_cube_fulltruss',
  },
  {
    id: 'truss_cube_halftruss',
    name: 'truss_cube_halftruss',
  },
  {
    id: 'truss_cube_notruss',
    name: 'truss_cube_notruss',
  },
  {
    id: 'truss_cubechain_fulltruss',
    name: 'truss_cubechain_fulltruss',
  },
  {
    id: 'truss_cubechain_halftruss',
    name: 'truss_cubechain_halftruss',
  },
  {
    id: 'truss_cubechain_notruss',
    name: 'truss_cubechain_notruss',
  },
  {
    id: 'quotation_marks',
    name: 'quotation_marks',
  },
];

export const INQUIRY_TYPES: InquiryType[] = [
  InquiryType.FullTimePosition,
  InquiryType.ProjectWork,
  InquiryType.Networking,
];
