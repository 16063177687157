import { useEffect, useMemo, useRef } from 'react';
import { Vector2 } from 'three';
import { THREE_CAMERA_DEPTH, THREE_DEFAULT_FOV } from '../../constants';
import { getVisibleHeightAtDepth } from '../../utilities';
import { TransdimensionalOffsetOptions } from '../../types';

export const useTransdimensionalOffsetLink = (
  options: TransdimensionalOffsetOptions
): {
  link: (element: HTMLElement | null) => void;
  unlink: () => void;
} => {
  const linkedRef = useRef<boolean>(false);
  const elementRef = useRef<HTMLElement | null>(null);
  const handleRect = useMemo(() => {
    return (rect: DOMRect) => {
      const element = elementRef.current;

      if (!element) return;

      const { scrollX, scrollY, innerWidth, innerHeight } = window;
      const containerRect =
        options.containerRect ?? new DOMRect(0, 0, innerWidth, innerHeight);

      const windowScroll = new Vector2(scrollX, scrollY);
      const containerSize = new Vector2(
        containerRect.width,
        containerRect.height
      );

      const defaultVisibleHeight = getVisibleHeightAtDepth(
        THREE_DEFAULT_FOV,
        THREE_CAMERA_DEPTH
      );
      const offsetVisibleHeight = getVisibleHeightAtDepth(
        THREE_DEFAULT_FOV,
        THREE_CAMERA_DEPTH - options.z
      );
      const screenSizeDifference = containerSize
        .clone()
        .multiplyScalar(offsetVisibleHeight - defaultVisibleHeight);
      const scale = defaultVisibleHeight / offsetVisibleHeight;

      const rectCenter = windowScroll
        .clone()
        .add(new Vector2(rect.x, rect.y))
        .add(new Vector2(rect.width, rect.height).divideScalar(2));

      const relativeScroll = containerSize
        .clone()
        .divideScalar(2)
        .add(new Vector2(scrollX, scrollY))
        .sub(rectCenter);
      const relativeScrollRatio = relativeScroll.divide(containerSize);

      const transform = `translate(${
        (relativeScrollRatio.x * screenSizeDifference.x) / 4
      }px, ${
        (relativeScrollRatio.y * screenSizeDifference.y) / 4
      }px) scale(${scale})`;

      if (options.handleTransform) {
        options.handleTransform(transform);
      }
    };
  }, [options.containerRect, options.z]);
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { target } = entry;
          const rect = target.getBoundingClientRect();

          handleRect(rect);
        }
      }),
    [handleRect]
  );
  const windowResizeHandler = (event: any) => {
    const element = elementRef.current;

    if (!element) return;

    const rect = element.getBoundingClientRect();

    handleRect(rect);
  };

  useEffect(() => {
    const element = elementRef.current;

    if (!element) return;

    const rect = element.getBoundingClientRect();

    handleRect(rect);
  }, [handleRect]);

  return {
    link: (element: HTMLElement | null) => {
      if (!linkedRef.current) {
        linkedRef.current = true;

        window.addEventListener('resize', windowResizeHandler, {
          passive: true,
        });
      }

      if (element === elementRef.current) return;

      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }

      elementRef.current = element;

      if (element) {
        setTimeout(() => {
          resizeObserver.observe(element);
        }, 0);
      }
    },
    unlink: () => {
      linkedRef.current = false;

      window.removeEventListener('resize', windowResizeHandler);
      resizeObserver.disconnect();
    },
  };
};
